import { Box, Grid, Typography } from '@mui/material';
import Link from 'next/link';
import React, { FC } from 'react';
import { PadBox } from '../containers/pad-box';

export const SrpLinksCard: FC = () => {
  const linkGroups = [
    {
      name: 'Regioner',
      links: [
        { text: 'Storkøbenhavn', url: '/brugte-biler/omraade-storkoebenhavn' },
        { text: 'Østjylland', url: '/brugte-biler/omraade-oestjylland' },
        { text: 'Nordjylland', url: '/brugte-biler/omraade-nordjylland' },
        { text: 'Bornholm', url: '/brugte-biler/omraade-bornholm' },
        { text: 'Fyn', url: '/brugte-biler/omraade-fyn' },
        {
          text: 'Midt/vestjylland',
          url: '/brugte-biler/omraade-midtvestjylland',
        },
        { text: 'Nordsjælland', url: '/brugte-biler/omraade-nordsjaelland' },
        {
          text: 'Sydsjælland og øer',
          url: '/brugte-biler/omraade-sydsjaelland-og-oeer',
        },
        { text: 'Vestsjælland', url: '/brugte-biler/omraade-vestsjaelland' },
      ],
    },
    {
      name: 'Kategorier',
      links: [
        { text: 'Alle biler', url: '/alle-biler' },
        { text: 'Alle brugte biler', url: '/brugte-biler' },
        { text: 'Elbiler', url: '/brugte-biler/braendstoftype-el' },
        { text: '7-personers biler', url: '/brugte-biler/biltype-mpv' },
        { text: 'Leasingbiler', url: '/leasing-biler' },
        { text: 'Busser', url: '/busser' },
        { text: 'Lastbiler', url: '/lastbiler' },
        { text: 'Autocampere', url: '/autocampere' },
        { text: 'Varevogne', url: '/varevogn-inkl-moms' },
        { text: 'Varevogne momsfri', url: '/varevogn-ekskl-moms' },
        { text: 'Biler uden afgift', url: '/biler-uden-afgift' },
      ],
    },
    {
      name: 'Populære mærker',
      links: [
        { text: 'Audi', url: '/brugte-biler/audi' },
        { text: 'BMW', url: '/brugte-biler/bmw' },
        { text: 'Citroën', url: '/brugte-biler/citroen' },
        { text: 'Ford', url: '/brugte-biler/ford' },
        { text: 'Hyundai', url: '/brugte-biler/hyundai' },
        { text: 'Kia', url: '/brugte-biler/kia' },
        { text: 'Mercedes', url: '/brugte-biler/mercedes' },
        { text: 'Opel', url: '/brugte-biler/opel' },
        { text: 'Peugeot', url: '/brugte-biler/peugeot' },
        { text: 'Renault', url: '/brugte-biler/renault' },
        { text: 'Skoda', url: '/brugte-biler/skoda' },
        { text: 'Tesla', url: '/brugte-biler/tesla' },
        { text: 'Toyota', url: '/brugte-biler/toyota' },
        { text: 'Volvo', url: '/brugte-biler/volvo' },
        { text: 'VW', url: '/brugte-biler/vw' },
      ],
    },
    {
      name: 'Populære serier',
      links: [
        {
          text: 'Mercedes A-klasse',
          url: '/brugte-biler/mercedes/a-klasse',
        },
        {
          text: 'Mercedes B-klasse',
          url: '/brugte-biler/mercedes/b-klasse',
        },
        {
          text: 'Mercedes C-klasse',
          url: '/brugte-biler/mercedes/c-klasse',
        },
        { text: 'BMW 1-serie', url: '/brugte-biler/bmw/1-serie' },
        { text: 'BMW 2-serie', url: '/brugte-biler/bmw/2-serie' },
        { text: 'BMW 3-serie', url: '/brugte-biler/bmw/3-serie' },
      ],
    },
  ];

  return (
    <>
      <Typography variant="h2">Andre bilsøgninger på Bilhandel</Typography>
      <PadBox>
        {linkGroups.map((group, index) => {
          return (
            <Box key={index} marginTop={2}>
              <Typography variant="h3" fontSize={20}>
                {group.name}
              </Typography>
              <Box display={'flex'} flexWrap={'wrap'}>
                {group.links.map((link, linkIndex) => {
                  return (
                    <Link key={linkIndex} href={link.url}>
                      <Typography marginRight={2}>{link.text}</Typography>
                    </Link>
                  );
                })}
              </Box>
            </Box>
          );
        })}
      </PadBox>
    </>
  );
};
