import { updateSRPRoute } from '@/web/util/helpers/srp-url-helpers';
import { NextRouter } from 'next/router';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { ApiSearchSearchPostRequest, FieldUsage, SearchApi } from '../lib-api';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { setLastSearchRequest } from '../store/reducers/AuthSlice';
import { SetSrpSearchData } from '../store/reducers/SRPSlice';
import { defaultApiConfiguration, useApi } from '../util/api';
import { useBrandModel } from './brand-model-hook';
import { useRequestHelper } from './request-helper';

interface PropsFromParent {
  router?: NextRouter;
  updateUrlOnChange: boolean;
}

function useSrpSubmit() {
  const [localSubmitCount, setLocalSubmitCount] = useState(0);
  const submitCount = useAppSelector(state => state.srpReducer.submitCount);

  const onSubmitted = useCallback(() => {
    if (localSubmitCount > submitCount) {
      setLocalSubmitCount(submitCount);
    } else {
      setLocalSubmitCount(localSubmitCount + 1);
    }
  }, [localSubmitCount, setLocalSubmitCount, submitCount]);

  const shouldSubmit = useMemo(() => {
    return localSubmitCount !== submitCount;
  }, [submitCount, localSubmitCount]);

  return {
    shouldSubmit: shouldSubmit,
    onSubmitted: onSubmitted,
  };
}

const searchApi = new SearchApi(defaultApiConfiguration);

export enum SrpViewMode {
  List,
  Gallery,
}

export function useSrp(props: PropsFromParent) {
  const { search, loadingState, submitSearch } = useSearch(props);
  const searchOptions = useAppSelector(state => state.srpReducer.searchOptions);
  const [viewMode, setViewMode] = useState(SrpViewMode.List);
  const { shouldSubmit, onSubmitted } = useSrpSubmit();
  useBrandModel({ options: searchOptions, mode: FieldUsage.Search });

  const onSearch = useCallback(async () => {
    if (shouldSubmit) {
      await search(props.updateUrlOnChange);
      onSubmitted();
    }
  }, [search, props.updateUrlOnChange, shouldSubmit, onSubmitted]);

  useEffect(() => {
    onSearch();
  }, [onSearch]);

  return {
    viewMode,
    setViewMode,
    submitSearch,
    loadingState,
  };
}

export function useSearch(props: PropsFromParent) {
  const searchOptions = useAppSelector(state => state.srpReducer.searchOptions);
  const dispatch = useAppDispatch();
  const { handleRequest, requestState } = useRequestHelper();

  const getPageFromUrl = useCallback((path: string) => {
    let pathComponents = path.split('?')[0].split('/');
    let result = pathComponents[pathComponents.length - 1];
    return result;
  }, []);

  const pageChanged = useCallback(
    (oldPath: string, newPath: string) => {
      let old = getPageFromUrl(oldPath);
      let cur = getPageFromUrl(newPath);
      return old !== cur && (old.includes('side') || cur.includes('side'));
    },
    [getPageFromUrl],
  );

  const handleRouting = useCallback(
    (shallow: boolean, replace: boolean) => {
      let route = updateSRPRoute(
        searchOptions,
        false,
        props.router.asPath.includes('/s/'),
      );

      let pathToPush = route.pathname + route.search;
      let pageHasChanged = pageChanged(props.router.asPath, pathToPush);
      if (props.router.asPath !== pathToPush) {
        if (replace && !pageHasChanged) {
          props.router.replace(route, undefined, {
            shallow: shallow,
          });
        } else {
          props.router.push(route, undefined, {
            shallow: shallow,
          });
        }
      }
    },
    [searchOptions, pageChanged],
  );

  const request: ApiSearchSearchPostRequest = useMemo(() => {
    var frontpage = props.router.route === '/';
    var extended = props.router.route === '/udvidet-soegning/[[...slug]]';

    if (frontpage) {
      return {
        searchPageOptions: { ...searchOptions, pageSize: 0 },
        excludeAggregations: true,
      } as ApiSearchSearchPostRequest;
    }
    if (extended) {
      return {
        searchPageOptions: { ...searchOptions, pageSize: 0 },
      } as ApiSearchSearchPostRequest;
    }
    return { searchPageOptions: searchOptions } as ApiSearchSearchPostRequest;
  }, [searchOptions]);

  const search = useCallback(
    async (shouldUpdateUrlOnChange: boolean) => {
      if (
        shouldUpdateUrlOnChange &&
        !props.router.route.includes(searchOptions.category.value)
      ) {
      } else {
        let response = searchApi.apiSearchSearchPost(request);
        let [result] = await handleRequest(response);
        dispatch(SetSrpSearchData(result));
      }
      if (shouldUpdateUrlOnChange) {
        handleRouting(true, true);
      }
      dispatch(setLastSearchRequest(searchOptions));
    },
    [searchOptions, dispatch, handleRouting, handleRequest, request],
  );

  const submitSearch = useCallback(() => {
    handleRouting(false, false);
  }, [handleRouting]);

  return {
    search,
    submitSearch,
    loadingState: requestState,
  };
}
