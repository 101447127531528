import { SearchPageOptions } from '@/shared/lib-api';
import slugify from 'slugify';
import {
  HandleBrandModel,
  HandleBrandModelForExtended,
  mapSearchOptionsToUrl,
} from './srp-object-to-url-helpers';
import { slugifyOptions } from './url-helpers';

type ValueType = 'Query' | 'Path' | 'CategoryPath';

export interface SrpUrlMappingItem {
  urlIdentifier: string;
  searchPageOptionsIdentifier: keyof SearchPageOptions;
  searchPageSecondaryOptionsIdentifier?: keyof SearchPageOptions;
  valueType: ValueType;
  index?: number;
}

const extendedMappingList: SrpUrlMappingItem[] = [
  {
    urlIdentifier: 'biltype=',
    searchPageOptionsIdentifier: 'carTypes',
    valueType: 'Query',
  },
  {
    urlIdentifier: 'braendstoftype=',
    searchPageOptionsIdentifier: 'fuelTypes',
    valueType: 'Query',
  },
  {
    urlIdentifier: 'aargang=',
    searchPageOptionsIdentifier: 'yearFrom',
    valueType: 'Query',
  },
  {
    urlIdentifier: 'pris=',
    searchPageOptionsIdentifier: 'priceFrom',
    valueType: 'Query',
  },
  {
    urlIdentifier: 'leasingtype=',
    searchPageOptionsIdentifier: 'leasingType',
    valueType: 'Query',
  },
  {
    urlIdentifier: 'kategori=',
    searchPageOptionsIdentifier: 'category',
    valueType: 'Query',
  },
  {
    urlIdentifier: 'mdl-ydelse=',
    searchPageOptionsIdentifier: 'pricePerMonthFrom',
    valueType: 'Query',
  },
];

const srpMappingList: SrpUrlMappingItem[] = [
  {
    urlIdentifier: 'biltype-',
    searchPageOptionsIdentifier: 'carTypes',
    valueType: 'Path',
    index: 3,
  },
  {
    urlIdentifier: 'side-',
    searchPageOptionsIdentifier: 'pageNumber',
    valueType: 'Path',
    index: 7,
  },
  {
    urlIdentifier: 'braendstoftype-',
    searchPageOptionsIdentifier: 'fuelTypes',
    valueType: 'Path',
    index: 4,
  },
  {
    urlIdentifier: 'omraade-',
    searchPageOptionsIdentifier: 'area',
    valueType: 'Path',
    index: 5,
  },
  {
    urlIdentifier: 'by-',
    searchPageOptionsIdentifier: 'city',
    valueType: 'Path',
    index: 6,
  },
  {
    urlIdentifier: 'gearkasse=',
    searchPageOptionsIdentifier: 'gearBoxTypes',
    valueType: 'Query',
  },
  {
    urlIdentifier: 'farve=',
    searchPageOptionsIdentifier: 'colors',
    valueType: 'Query',
  },
  {
    urlIdentifier: 'annoncetype=',
    searchPageOptionsIdentifier: 'profileType',
    valueType: 'Query',
  },
  {
    urlIdentifier: 'udstyr=',
    searchPageOptionsIdentifier: 'equipment',
    valueType: 'Query',
  },
  {
    urlIdentifier: 'leasingtype=',
    searchPageOptionsIdentifier: 'leasingType',
    valueType: 'Query',
  },
  {
    urlIdentifier: 'aargang=',
    searchPageOptionsIdentifier: 'yearFrom',
    valueType: 'Query',
  },
  {
    urlIdentifier: 'kilometer=',
    searchPageOptionsIdentifier: 'milageFrom',
    valueType: 'Query',
  },
  {
    urlIdentifier: 'hestekraefter=',
    searchPageOptionsIdentifier: 'horsepowerFrom',
    valueType: 'Query',
  },
  {
    urlIdentifier: 'pris=',
    searchPageOptionsIdentifier: 'priceFrom',
    valueType: 'Query',
  },
  {
    urlIdentifier: 'afstand=',
    searchPageOptionsIdentifier: 'zipCode',
    searchPageSecondaryOptionsIdentifier: 'radius',
    valueType: 'Query',
  },
  {
    urlIdentifier: 'soegning=',
    searchPageOptionsIdentifier: 'searchQuery',
    valueType: 'Query',
  },
  {
    urlIdentifier: 'sort=',
    searchPageOptionsIdentifier: 'sort',
    valueType: 'Query',
  },
  {
    urlIdentifier: 'mdl-ydelse=',
    searchPageOptionsIdentifier: 'pricePerMonthFrom',
    valueType: 'Query',
  },
  {
    urlIdentifier: 'capacity=',
    searchPageOptionsIdentifier: 'batteryCapacityFrom',
    valueType: 'Query',
  },
  {
    urlIdentifier: 'distance=',
    searchPageOptionsIdentifier: 'distanceFrom',
    valueType: 'Query',
  },
  {
    urlIdentifier: 'kml=',
    searchPageOptionsIdentifier: 'kmPerLiterFrom',
    valueType: 'Query',
  },
  {
    urlIdentifier: 'onlinesiden=',
    searchPageOptionsIdentifier: 'onlineSince',
    valueType: 'Query',
  },
  {
    urlIdentifier: 'antaldoere=',
    searchPageOptionsIdentifier: 'doorCount',
    valueType: 'Query',
  },
  {
    urlIdentifier: 'limitsearch=',
    searchPageOptionsIdentifier: 'limitSearch',
    valueType: 'Query',
  },
  {
    urlIdentifier: 'limitSeatsAndDoors=',
    searchPageOptionsIdentifier: 'limitSeatsAndDoors',
    valueType: 'Query',
  },
  {
    urlIdentifier: 'srpcategory=',
    searchPageOptionsIdentifier: 'category',
    valueType: 'CategoryPath',
  },
];

export const getExtendedRoute = (
  options: SearchPageOptions,
  basepath: string,
): URL => {
  let keyValuePairs = Object.entries(options);
  let resultUrl = new URL(basepath + '/udvidet-soegning');
  resultUrl = HandleBrandModelForExtended(resultUrl, options);
  for (var i = 0; i < keyValuePairs.length; i++) {
    let key = keyValuePairs[i][0].replace('From', '').replace('To', '');
    let value = keyValuePairs[i][1];
    resultUrl = mapSearchOptionsToUrl(
      resultUrl,
      key,
      value,
      keyValuePairs,
      extendedMappingList,
    );
  }

  return resultUrl;
};

export const updateSRPRoute = (
  options: SearchPageOptions,
  indexedSrp: boolean,
  addcrawl?: boolean,
): URL => {
  let keyValuePairs = Object.entries(options);
  let resultUrl = new URL(window.location.origin + '/s/alle-biler');
  if (indexedSrp) {
    resultUrl = new URL(window.location.origin + '/alle-biler');
  }
  resultUrl = HandleBrandModel(resultUrl, options);
  for (var i = 0; i < keyValuePairs.length; i++) {
    let key = keyValuePairs[i][0].replace('From', '').replace('To', '');
    let value = keyValuePairs[i][1];
    resultUrl = mapSearchOptionsToUrl(
      resultUrl,
      key,
      value,
      keyValuePairs,
      srpMappingList,
    );
  }
  if (addcrawl) {
    resultUrl.searchParams.append('link', 'yes');
  }

  return resultUrl;
};

export const GetSrpUrl = (
  category: string,
  make?: string,
  model?: string,
  variant?: string,
): string => {
  let url: string = `/${slugify(category, slugifyOptions)}`;

  if (make) {
    url += `/${slugify(make, slugifyOptions)}`;
    if (model) {
      url += `/${slugify(model, slugifyOptions)}`;
      if (variant) {
        url += `?variant=${encodeURIComponent(variant)}`;
      }
    }
  }

  return url;
};
