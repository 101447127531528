import { useCallback, useEffect, useMemo } from 'react';
import {
  ClassifiedCreationOptions,
  FieldUsage,
  SearchApi,
  SearchPageAttribute,
  SearchPageOptions,
} from '../lib-api';
import { useAppDispatch } from '../store/hooks';
import {
  initialSearchAttribute,
  SetSRPModels,
  SetSrpSearchOption,
} from '../store/reducers/SRPSlice';
import { setSyiModels } from '../store/reducers/SYISlice';
import { defaultApiConfiguration, useApi } from '../util/api';
import { useMutableValue, useToggle } from './common/useToggle';

interface PropsFromParent {
  options: SearchPageOptions | ClassifiedCreationOptions;
  mode: FieldUsage;
  onSetModels?: (name: string, values: SearchPageAttribute) => void;
}

const searchApi = new SearchApi(defaultApiConfiguration);

export function useBrandModel({ options, mode, onSetModels }: PropsFromParent) {
  const [setShouldLoadModels, shouldLoadRef] = useToggle(
    options.makeModelVariantOptions.find(p => p.brand).brand.value === '',
  );
  const { makeModelVariantOptions } = options;

  const lastModelValue =
    makeModelVariantOptions[makeModelVariantOptions.length - 1]?.model.value;

  const lastBrandData =
    makeModelVariantOptions[makeModelVariantOptions.length - 1]?.brand;

  const dispatch = useAppDispatch();

  const setModelAttributes = useCallback(
    async (models: SearchPageAttribute[]) => {
      if (mode === FieldUsage.Search) {
        dispatch(SetSRPModels(models));
      } else {
        dispatch(setSyiModels(models));
        if (!models.find(p => p.value === lastModelValue)) {
          let modelField = `makeModelVariantOptions.0.model`;
          onSetModels(modelField, initialSearchAttribute);
        }
      }
    },
    [dispatch, onSetModels, mode, lastModelValue],
  );

  const updateModels = useCallback(
    async (brand: string) => {
      let models = await searchApi.apiSearchSearchmodelattributesGet({
        brand: brand,
        isSyi: mode === FieldUsage.Create,
      });

      await setModelAttributes(models);
    },
    [setModelAttributes, mode],
  );

  useEffect(() => {
    if (!shouldLoadRef.current) {
      setShouldLoadModels(true);
      return;
    }
    if (lastBrandData.value !== '') {
      (async () => {
        await updateModels(lastBrandData.label);
      })();
    }
  }, [lastBrandData, updateModels, setShouldLoadModels, shouldLoadRef]);

  return {};
}
