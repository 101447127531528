import { Colors } from '@/shared/util/colors';
import { Box, Container, Grid, Typography } from '@mui/material';
import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import CheckMark from '@/shared/icons/checkmark-green.svg';
import TextWithIcon from '../default-components/text-with-icon/text-with-icon';
import Link from 'next/link';
import { useAppDispatch } from '@/shared/store/hooks';
import { resetFlow } from '@/shared/store/reducers/SYISlice';
import { useRouter } from 'next/router';
import { SearchCardContent } from './search-card-content';

const Data = [
  'SRP.SubTopBar.FirstText',
  'SRP.SubTopBar.SecondText',
  'SRP.SubTopBar.ThirdText',
  'SRP.SubTopBar.FourthText',
];

export const SearchCard: FC = () => {
  const { t } = useTranslation();
  const searchContentStyle: React.CSSProperties = {
    filter: 'drop-shadow(0px 0px 24px rgba(0,0,0,0.25))',
  };
  const dispatch = useAppDispatch();
  const router = useRouter();
  const itemText = useMemo(
    // eslint-disable-next-line react/display-name
    () => (d: string, itemIndex: number) =>
      (
        <TextWithIcon
          key={itemIndex}
          icon={CheckMark.src}
          iconHeight={12}
          iconWidth={16}
          text={t(d)}
          textcolor={Colors.White}
          fontSize={19}
          lineHeight={1.9}
        />
      ),
    [t],
  );
  const subTopBars = useMemo(
    () =>
      Data.map((d, itemIndex, array) => {
        if (itemIndex === array.length - 1) {
          return (
            <Link
              key={itemIndex}
              href="/saelg-bil"
              onClick={e => {
                if (router.route !== '/saelg-bil') {
                  dispatch(resetFlow());
                }
              }}>
              {itemText(d, itemIndex)}
            </Link>
          );
        } else if (itemIndex === 0) {
          return (
            <Link
              key={itemIndex}
              target="_blank"
              href="https://dk.trustpilot.com/review/bilhandel.dk">
              {itemText(d, itemIndex)}
            </Link>
          );
        } else {
          return itemText(d, itemIndex);
        }
      }),
    [itemText, dispatch, router],
  );
  return (
    <Box
      bgcolor={Colors.DarkBlue}
      paddingTop={{ xs: '0px', sm: '69px' }}
      paddingBottom={{ xs: '44px', sm: '69px' }}
      display={'flex'}
      alignItems={'center'}>
      <Container>
        <Grid alignItems="center" container paddingX={2} columnSpacing={2}>
          <Grid
            item
            display={{ xs: 'none', sm: 'flex' }}
            flexDirection={'column'}
            alignItems={'center'}
            md={6}
            xs={12}>
            <Typography
              paddingBottom={3}
              variant="h1"
              textAlign={'center'}
              fontWeight={700}
              fontSize={50}
              maxWidth={460}
              lineHeight={1.4}
              color={Colors.White}>
              {t('SearchCard.Title')}
            </Typography>
            {subTopBars}
          </Grid>
          <Grid item md={6} xs={12} style={searchContentStyle}>
            <SearchCardContent />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};
