export const Areas = [
  { text: 'Jylland', value: 'jylland', prepos: 'i' },
  { text: 'Sjælland', value: 'sjaelland', prepos: 'på' },
  { text: 'Fyn', value: 'fyn', prepos: 'på' },
  { text: 'Nordjylland', value: 'nordjylland', prepos: 'i' },
  { text: 'Østjylland', value: 'oestjylland', prepos: 'i' },
  { text: 'Midt/Vestjylland', value: 'midtvestjylland', prepos: 'i' },
  { text: 'Sønderjylland', value: 'soenderjylland', prepos: 'i' },
  { text: 'Storkøbenhavn', value: 'storkoebenhavn', prepos: 'i' },
  { text: 'Nordsjælland', value: 'nordsjaelland', prepos: 'i' },
  { text: 'Vestsjælland', value: 'vestsjaelland', prepos: 'i' },
  { text: 'Sydsjælland og øerne', value: 'sydsjaelland-og-oeer', prepos: 'på' },
  { text: 'Bornholm', value: 'bornholm', prepos: 'på' },
];
