import { useApi } from '@/shared/util/api';
import { Box, Button, Grid, IconButton, Typography } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { ClassifiedItem } from '@/shared/lib-api';
import Cookies from 'js-cookie';
import BasicCarListCard from '../cards/basic-car-card/basic-car-list-card';
import { BasicCarCardType } from '@/shared/hooks/basic-car-hook';
import { BasicCarGalleryCard } from '../cards/basic-car-card/basic-car-gallery-card';
import { useRequestHelper } from '@/shared/hooks/request-helper';
import { LoadingState } from '@/shared/util/general-helpers';
import CarPostsSection from './car-posts-section';

export const RecommendationSection = props => {
  const { searchApi } = useApi();
  const [items, setItems] = useState<ClassifiedItem[]>([]);
  const { handleRequest, requestState } = useRequestHelper();

  const load = useCallback(async () => {
    const cookie = Cookies.get('_tty');

    if (!cookie) {
      return;
    }
    const req = searchApi.apiSearchRecommendationsPost({
      frontPageRecommendationsRequest: {
        tTyUserId: cookie,
      },
    });

    const [result] = await handleRequest(req);
    setItems(result);
  }, [searchApi, handleRequest]);

  useEffect(() => {
    load();
  }, [load]);

  return (
    <CarPostsSection
      link={new URL('https://bilhandel.dk/s/alle-biler')}
      id="frontpage-itemwise-link"
      headerText={'Udvalgte biler'}
      Cars={items ? items : []}
    />
  );
};
