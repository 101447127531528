import { SearchPageAttribute, SearchPageOptions } from '@/shared/lib-api';
import { createRoute } from '@/web/views/components/SRP/bottom-link-srp';
import slugify from 'slugify';
import { SrpUrlMappingItem } from './srp-url-helpers';
import { slugifyOptions } from './url-helpers';

export function mapSearchOptionsToUrl(
  resultUrl: URL,
  key: string,
  value: any,
  keyValuePairs: [string, any][],
  srpMappingList: SrpUrlMappingItem[],
) {
  let mappingItem = srpMappingList.find(p =>
    p.searchPageOptionsIdentifier.includes(key),
  );
  if (mappingItem === undefined) {
    return resultUrl;
  }

  if (hasSecondaryIdentifier(mappingItem)) {
    return HandleSecondaryMapping(resultUrl, mappingItem, value, keyValuePairs);
  }

  if (Number.isInteger(value)) {
    if (value === 1) {
      return resultUrl;
    }
    return HandleDefaultMapping(resultUrl, mappingItem, value.toString());
  }

  if (isStringOption(value)) {
    return HandleDefaultMapping(resultUrl, mappingItem, value);
  }
  if (isSearchPageAttributeArray(value)) {
    if (value.length === 0) {
      return resultUrl;
    }
    return HandleArrayValue(resultUrl, value, mappingItem);
  }
  if (isSearchPageAttribute(value)) {
    let urlValue = value.value;
    if (mappingItem.searchPageOptionsIdentifier.includes('From')) {
      return HandleIntervalMapping(resultUrl, mappingItem, keyValuePairs);
    } else {
      return HandleDefaultMapping(resultUrl, mappingItem, urlValue);
    }
  }
  return resultUrl;
}

function hasSecondaryIdentifier(mappingItem: SrpUrlMappingItem) {
  return mappingItem.searchPageSecondaryOptionsIdentifier !== undefined;
}

function HandleArrayValue(
  result: URL,
  value: any[],
  mappingItem: SrpUrlMappingItem,
) {
  let urlvalue = value[0].value;
  if (mappingItem.valueType === 'Query') {
    return HandleDefaultMapping(
      result,
      mappingItem,
      value.map(p => p.value).join(','),
    );
  }
  HandleDefaultMapping(result, mappingItem, urlvalue);
  return HandleDefaultMapping(
    result,
    {
      ...mappingItem,
      valueType: 'Query',
      urlIdentifier: mappingItem.urlIdentifier.replace('-', '='),
    },
    value
      .filter((p, i) => i !== 0)
      .map(p => p.value)
      .join(','),
  );
}

function isStringOption(value: any) {
  return typeof value === typeof '';
}

function isSearchPageAttributeArray(value: any) {
  return Array.isArray(value);
}

function isSearchPageAttribute(value: any) {
  return value?.value !== undefined;
}

function HandleIntervalMapping(
  resultUrl: URL,
  mappingItem: SrpUrlMappingItem,
  keyValuePairs: [string, SearchPageAttribute][],
) {
  if (resultUrl.search.includes(mappingItem.urlIdentifier)) {
    return resultUrl;
  }
  let urlValue = '';
  let keyFrom = mappingItem.searchPageOptionsIdentifier;
  let keyTo = mappingItem.searchPageOptionsIdentifier.replace('From', 'To');
  let keyValueFrom = keyValuePairs.find(p => p[0] === keyFrom);
  let keyValueTo = keyValuePairs.find(p => p[0] === keyTo);
  if (keyValueFrom !== undefined && keyValueFrom[1].value !== '') {
    urlValue += keyValueFrom[1].value;
  }

  if (keyValueTo !== undefined && keyValueTo[1].value !== '') {
    urlValue += `,${keyValueTo[1].value}`;
  }
  return HandleDefaultMapping(resultUrl, mappingItem, urlValue);
}

function HandleSecondaryMapping(
  resultUrl: URL,
  mappingItem: SrpUrlMappingItem,
  value: string,
  keyValuePairs: [string, any][],
) {
  if (value === '') {
    return resultUrl;
  }
  let urlValue = value;

  let secondaryValue = keyValuePairs.find(
    p => p[0] === mappingItem.searchPageSecondaryOptionsIdentifier,
  );

  if (secondaryValue !== undefined && secondaryValue[1].value !== '') {
    urlValue += `,${secondaryValue[1].value}`;
  }
  return HandleDefaultMapping(resultUrl, mappingItem, urlValue);
}

function HandleDefaultMapping(
  resultUrl: URL,
  mappingItem: SrpUrlMappingItem,
  value: string,
) {
  if (value === '') {
    return resultUrl;
  }
  switch (mappingItem.valueType) {
    case 'Path':
      resultUrl.pathname = createRoute(
        resultUrl.pathname,
        mappingItem.index,
        mappingItem.urlIdentifier + value,
        true,
        true,
        undefined,
        true,
      );
      return resultUrl;
    case 'Query':
      if (resultUrl.search.includes('?')) {
        resultUrl.search += `&${mappingItem.urlIdentifier}${value}`;
      } else {
        resultUrl.search += `?${mappingItem.urlIdentifier}${value}`;
      }
      return resultUrl;
    case 'CategoryPath':
      resultUrl.pathname = resultUrl.pathname.replace('alle-biler', value);
      return resultUrl;
    default:
      return resultUrl;
  }
}

export function HandleBrandModelForExtended(
  resultUrl: URL,
  options: SearchPageOptions,
) {
  let brandValue = options.makeModelVariantOptions[0].brand.value;
  if (brandValue !== '') {
    HandleDefaultMapping(
      resultUrl,
      {
        valueType: 'Query',
        urlIdentifier: 'brand=',
        searchPageOptionsIdentifier: 'makeModelVariantOptions',
      },
      brandValue,
    );
  }
  let modelValue = options.makeModelVariantOptions[0].model.label;
  if (modelValue !== '') {
    HandleDefaultMapping(
      resultUrl,
      {
        valueType: 'Query',
        urlIdentifier: 'model=',
        searchPageOptionsIdentifier: 'makeModelVariantOptions',
      },
      slugify(modelValue, slugifyOptions),
    );
  }
  return resultUrl;
}

export function HandleBrandModel(resultUrl: URL, options: SearchPageOptions) {
  let brandValue = options.makeModelVariantOptions[0].brand.value;
  if (brandValue !== '') {
    resultUrl.pathname += `/${brandValue}`;
  }
  let modelValue = options.makeModelVariantOptions[0].model.value.includes(',')
    ? options.makeModelVariantOptions[0].model.label
    : options.makeModelVariantOptions[0].model.value;
  if (modelValue !== '') {
    resultUrl.pathname += `/${slugify(modelValue, slugifyOptions)}`;
  }
  let variantValue = options.makeModelVariantOptions[0].variant;
  HandleDefaultMapping(
    resultUrl,
    {
      valueType: 'Query',
      urlIdentifier: 'variant=',
      searchPageOptionsIdentifier: 'makeModelVariantOptions',
    },
    variantValue,
  );

  for (var i = 1; i < options.makeModelVariantOptions.length; i++) {
    HandleDefaultMapping(
      resultUrl,
      {
        valueType: 'Query',
        urlIdentifier: `extrafields${i}=`,
        searchPageOptionsIdentifier: 'makeModelVariantOptions',
      },
      options.makeModelVariantOptions[i].brand.value +
        ',' +
        (options.makeModelVariantOptions[i].model?.label &&
          slugify(
            options.makeModelVariantOptions[i].model.value.includes(',')
              ? options.makeModelVariantOptions[i].model.label
              : options.makeModelVariantOptions[i].model.value,
            slugifyOptions,
          )) +
        ',' +
        options.makeModelVariantOptions[i].variant,
    );
  }
  return resultUrl;
}
