import { useScreenSizeContext } from '@/pages/_app';
import { SearchPageAttribute } from '@/shared/lib-api/models';
import { slugifyOptions } from '@/web/util/helpers/url-helpers';
import { Grid, Typography } from '@mui/material';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { useCallback } from 'react';
import slugify from 'slugify';

export const BottomLinkSrp: React.FC<{
  fields: SearchPageAttribute[];
  allFields: string[];
  urlIndex: number;
  text?: string;
  prefix?: string;
}> = ({ fields, allFields, urlIndex, text, prefix }) => {
  const router = useRouter();
  const { isMobile } = useScreenSizeContext();
  const getRoute = useCallback(
    (value: string) => {
      let valueToPush = prefix ? prefix + value : value;
      return createRoute(
        router.asPath,
        urlIndex,
        valueToPush,
        false,
        false,
        allFields,
      );
    },
    [router, urlIndex, prefix, allFields],
  );
  return (
    <>
      <Grid container>
        {fields.map(item => (
          <Grid xs={6} sm={3} md={2} item key={item.label}>
            <Link
              className="bottom-link-srp"
              href={getRoute(
                item.isGroup ? slugify(item.label, slugifyOptions) : item.value,
              )}>
              <Typography
                marginTop={isMobile ? 1 : 0}
                color="primary"
                fontSize="14px">
                {item.label}
              </Typography>
            </Link>
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export function createRoute(
  path: string,
  urlIndex: number,
  valueToPush?: string,
  includePage?: boolean,
  includeQuery?: boolean,
  allFields?: string[],
  addSlashS?: boolean,
) {
  let list = [];
  if (!addSlashS) {
    list = path.replace('/s/', '/').substring(1).split('?')[0].split('/');
  } else {
    list = path.replace('/s/', '').split('?')[0].split('/');
  }
  if (allFields) {
    list = [list[0]].concat(
      list.filter(p => allFields.includes(slugify(p, slugifyOptions))),
    );
  }
  let query = path.substring(1).split('?')[1];

  let deletedPageUrl = getDeletedPageUrl(list);
  let deletedCityUrl = getDeletedCityUrl(list);
  let deletedCarTypeUrl = getDeletedCarTypeUrl(list);
  let deletedFueltypeUrl = getDeletedFueltypeUrl(list);
  let deletedAreaUrl = getDeletedAreaUrl(list);
  if (list.length === 1) {
    list.push(''); //simulated make
  }
  if (list.length === 2) {
    list.push(''); //simulated model
  }
  list = list.concat(
    deletedCarTypeUrl,
    deletedFueltypeUrl,
    deletedAreaUrl,
    deletedCityUrl,
  );
  if (includePage) {
    list = list.concat(deletedPageUrl);
  }
  if (valueToPush && valueToPush.includes('?')) {
    query = valueToPush.replace('?', '');
  } else {
    if (list.length < urlIndex) {
      list.push(valueToPush);
    } else {
      list.splice(urlIndex, 0, valueToPush);
    }
  }

  return (
    (addSlashS ? '/s/' : '/') +
    list.filter(p => p !== '').join('/') +
    (query && includeQuery ? '?' + query : '')
  );
}

function getDeletedCityUrl(list: string[]) {
  return getDeletedUrl(list, 'by-');
}

function getDeletedCarTypeUrl(list: string[]) {
  return getDeletedUrl(list, 'biltype-');
}

function getDeletedFueltypeUrl(list: string[]) {
  return getDeletedUrl(list, 'braendstoftype-');
}

function getDeletedAreaUrl(list: string[]) {
  return getDeletedUrl(list, 'omraade-');
}

function getDeletedPageUrl(list: string[]) {
  return getDeletedUrl(list, 'side-');
}

function getDeletedUrl(list: string[], prefix: string) {
  let urlIndex = list.findIndex(p => p.startsWith(prefix));
  if (urlIndex !== -1) {
    return list.splice(urlIndex, 1);
  }
  return [''];
}
