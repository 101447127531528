import { SearchPageAttribute } from '@/shared/lib-api';
import {
  Box,
  Checkbox,
  CircularProgress,
  IconButton,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import * as React from 'react';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DefaultInputWrapper } from '../default-input-wrapper';
import { DropDownArrow } from '../../input-icons/dropdownIcon';
import { useScreenSizeContext } from '@/pages/_app';

export interface CustomInputSelectProps {
  value?: SearchPageAttribute | SearchPageAttribute[] | string;
  values: SearchPageAttribute[];
  name?: string;
  loadingAutoFillData?: boolean;
  multiple?: boolean;
  placeholder?: string;
  maxDropDownHeight?: boolean;
  disabledMulti?: boolean;
  onChanged?: (
    value: SearchPageAttribute | SearchPageAttribute[] | string,
    name?: string,
  ) => void;
}

function DefaultItemInputSelect(props: CustomInputSelectProps) {
  const { t } = useTranslation();
  const { isMobile } = useScreenSizeContext();
  const selectItems = React.useMemo(() => {
    return props?.values?.map((opt, index) => {
      const ref = React.createRef<HTMLButtonElement>();
      return (
        <MenuItem
          style={{
            height: isMobile ? 40 : 24,
            marginTop: isMobile ? 0 : 4,
            minHeight: 'unset',
          }}
          onClick={e => {
            if (props.multiple && !props.disabledMulti) {
              (ref.current.firstChild as any).click();
            }
          }}
          sx={{
            paddingLeft:
              props.multiple && !props.disabledMulti
                ? '0px!important'
                : 'initial',
          }}
          key={index}
          value={opt.value}>
          {props.multiple && !props.disabledMulti ? (
            <>
              <Box
                style={{
                  position: 'absolute',
                  backgroundColor: 'transparent',
                  height: '100%',
                  width: 50,
                  zIndex: 1,
                }}
              />
              <Checkbox
                ref={ref}
                style={{ zIndex: 0 }}
                size="small"
                defaultChecked={
                  (props.value as SearchPageAttribute[]).find(
                    p => p.value === opt.value,
                  ) !== undefined
                }
              />
              <Typography variant="body1" fontSize={16}>
                {t(opt.label)}
              </Typography>
            </>
          ) : (
            t(opt.label)
          )}
        </MenuItem>
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t, isMobile, props.values]);

  const [open, setOpen] = useState(false);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, [setOpen]);

  const onValueChanged = React.useCallback(
    (event: SelectChangeEvent<string | string[]>) => {
      if (props.onChanged) {
        if (props.multiple) {
          if (event.target.value === '') {
            props.onChanged([], props.name);
            return;
          }
          let multipleSelectAllOption = props.disabledMulti
            ? [event.target.value as string]
            : (event.target.value as string[]);
          var valsToUse = props.values.filter(p =>
            multipleSelectAllOption.some(t => t == p.value),
          );
          props.onChanged(valsToUse, props.name);
        } else {
          let multipleSelectAllOption = event.target.value as string;
          var valToUse = props.values.find(
            p => multipleSelectAllOption == p.value,
          );

          props.onChanged(valToUse, props.name);
        }
      }
    },
    [props],
  );

  const iconComponent = useMemo(() => {
    return (
      <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
        {!props.loadingAutoFillData && (
          <IconButton
            style={{
              padding: '2px',
              right: 8,
              position: 'absolute',
              transform: open ? 'scaleY(-1)' : 'none',
            }}
            onClick={handleOpen}>
            {DropDownArrow}
          </IconButton>
        )}
        {props.loadingAutoFillData && (
          <CircularProgress
            style={{
              right: 8,
              position: 'absolute',
            }}
            size={16}
          />
        )}
      </Box>
    );
  }, [handleOpen, open, props.loadingAutoFillData]);

  const renderValue = useCallback(
    (selected: string | string[]) => {
      if (props.multiple && (selected as string[]).length === 0) {
        return props.placeholder;
      }

      if (!props.multiple && selected === '') {
        return props.values[0]?.label;
      }

      if (props.multiple) {
        return (selected as string[])
          .map(
            selectedValue =>
              props.values.find(values => values.value === selectedValue)
                ?.label,
          )
          .join(', ');
      }

      if (!props.multiple) {
        return props.values.find(p => p.value === (selected as string))?.label;
      }
    },
    [props.multiple, props.placeholder, props.values],
  );

  const inputValue = useMemo(() => {
    if (props.multiple) {
      return props.values
        .filter(p =>
          (props.value as SearchPageAttribute[]).some(v => v.value === p.value),
        )
        .map(p => p.value);
    } else {
      if (props.value === '') {
        return props.value;
      }
      return (props.value as SearchPageAttribute).value;
    }
  }, [props.value, props.values, props.multiple]);

  return (
    <DefaultInputWrapper>
      <Select
        displayEmpty
        fullWidth
        MenuProps={{
          sx: {
            maxHeight: {
              sm: props.maxDropDownHeight ? '500px' : '300px',
              variant: 'selectedMenu',
            },
          },
        }}
        open={open}
        onOpen={handleOpen}
        onClose={handleClose}
        IconComponent={() => {
          return iconComponent;
        }}
        renderValue={renderValue}
        multiple={!props.disabledMulti && props.multiple}
        value={inputValue}
        onChange={onValueChanged}>
        {selectItems}
      </Select>
    </DefaultInputWrapper>
  );
}

export default React.memo(DefaultItemInputSelect);
