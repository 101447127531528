import { ClassifiedItem, SearchPageOptions } from '@/shared/lib-api';
import { useAppSelector } from '@/shared/store/hooks';
import { initialSearchPageOptions } from '@/shared/store/reducers/SRPSlice';
import { useApi } from '@/shared/util/api';
import { updateSRPRoute } from '@/web/util/helpers/srp-url-helpers';
import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import CarPostsSection from './car-posts-section';

type CarsYourAreaSectionProps = {
  defaultClassifieds: ClassifiedItem[];
};

export const CarsYourAreaSection: React.FC<
  CarsYourAreaSectionProps
> = props => {
  const { t } = useTranslation();
  const { searchApi } = useApi();
  const [cars, setCars] = useState<ClassifiedItem[]>(props.defaultClassifieds);
  const [heading, setHeading] = useState<string>('Populære biler');
  const zipCode = useAppSelector(
    state => state.auth.notLoggedInUserData.zipCode,
  );

  const searchOptions = useMemo<SearchPageOptions | undefined>(() => {
    if (zipCode === undefined) {
      return undefined;
    }
    return {
      ...initialSearchPageOptions,
      pageSize: 6,
      zipCode: zipCode.toString(),
      radius: { value: '25', label: '25 km' },
    };
  }, [zipCode]);
  const getNewVehicles = useCallback(async () => {
    if (searchOptions !== undefined) {
      let result = await searchApi.apiSearchSearchPost({
        searchPageOptions: searchOptions,
      });
      setCars(result.items);
      setHeading(t('Labels.CarsInYourArea'));
    }
  }, [searchOptions, searchApi, t]);

  useEffect(() => {
    getNewVehicles();
  }, [getNewVehicles]);
  return (
    <>
      {cars && cars.length > 0 && (
        <CarPostsSection
          link={
            searchOptions
              ? updateSRPRoute(searchOptions, false)
              : new URL('https://bilhandel.dk/s/alle-biler')
          }
          id="frontpage-area-link"
          headerText={heading}
          Cars={cars ? cars : []}
        />
      )}
    </>
  );
};

export default React.memo(CarsYourAreaSection);
