import { Typography } from '@mui/material';
import * as React from 'react';

export const DefaultItemLabel: React.FC<{
  header?: string;
}> = props => {
  return props.header ? (
    <Typography
      whiteSpace={'nowrap'}
      color={'primary'}
      fontWeight={700}
      display="block"
      variant={'h8'}>
      {props.header}
    </Typography>
  ) : (
    <></>
  );
};
