import { Box, Container, Grid, Typography } from '@mui/material';
import React, { useCallback } from 'react';
import { BasicToolCard, BasicToolCardProps } from '../cards/basic-tool-card';
import carAssisant from '../../../../shared/icons/profession-man-telecommuncation.svg';
import searchCar from '../../../../shared/icons/car-action-search.svg';
import barCodeTag from '../../../../shared/icons/bar-code-tag.svg';
import { useTranslation } from 'react-i18next';
import { OpenExternalLink } from '@/web/util/helpers/open-external-link';
import { SiteSettings, getSiteSettings } from '@/web/sitesettings';

function ToolsSection(props: { host: string }) {
  const { t } = useTranslation();

  const items: BasicToolCardProps[] = [
    {
      id: 'btn-tools-chat',
      buttonTitle: 'Læs mere her',
      imgSrc: carAssisant.src,
      title: 'Guides til bilmodeller',
      body: 'Læs vores guides med bl.a. tests og information, som er godt at vide, inden man køber ny bil. Vi har guides til alle de mest populære bilmærker og modeller. Vi har også guides til dig, der overvejer at lease en bil.',
      link: 'https://bilhandel.dk/guide',
    },
    {
      id: 'btn-tools-price',
      buttonTitle: 'Få bud på din bil',
      imgSrc: barCodeTag.src,
      title: 'Hvad er min bil værd?',
      body: 'Har du en bil du ønsker at sælge, kan du med TjekBil SmartSalg få konkrete bud på din bil fra forhandlere, på bare 48 timer. Det er en meget enkel og hurtig måde at sælge sin bil på, og du slipper for alt besværet.',
      onClick: () =>
        OpenExternalLink(
          'https://tjekbil.dk/smartsalg?utm_source=bilhandel&utm_campaign=forside-boks',
        ),
    },
    {
      id: 'btn-tools-tjekbil',
      buttonTitle: 'Lav opslag',
      imgSrc: searchCar.src,
      title: 'Tjek bilen før du køber',
      body: 'Har du brug for hjælp til dig bilkøb, eller spørgsmål til regler omkring leasing, privat salg, slutseddel, eller noget helt andet bilrelateret, så sidder vi klar til at hjælpe dig!',
      onClick: () => OpenExternalLink('https://tjekbil.dk'),
    },
  ];

  const siteSettings = getSiteSettings(props.host);
  if (siteSettings.name === 'BilEkspressen') {
    items.push({
      id: 'btn-tools-tjekbil',
      buttonTitle: 'Sælg din bil',
      imgSrc: searchCar.src,
      title: 'Sæt din bil til salg',
      body: 'Ønsker du en nem, hurtig, gratis og uforpligtende salg af din bil, kan du gøre det gennem TjekBil. Vi sender din bil ud til vores store netværk af bilforhandlere, som derefter vil kontakte dig og give et tilbud på din bil.',
      onClick: () =>
        OpenExternalLink(
          'https://www.tjekbil.dk/saelg-din-bil?utm_source=bilekspressen',
        ),
    });
  }

  return (
    <>
      <Typography variant={'h2'}>{t('Labels.ToolsForYou')} </Typography>
      <Grid container rowSpacing={3} columnSpacing={3}>
        {items.map((item, itemIndex) => (
          <Grid key={itemIndex} item xs={12} md={4}>
            <BasicToolCard {...item} />
          </Grid>
        ))}
      </Grid>
    </>
  );
}

export default ToolsSection;
