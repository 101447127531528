import { Colors } from '@/shared/util/colors';
import { Box, Button, Grid, Typography } from '@mui/material';
import Image from '@/web/views/components/image/image';
import * as React from 'react';
import { PadBox } from '../containers/pad-box';

export interface BasicToolCardProps {
  id: string;
  imgSrc: string;
  imgWidth?: number;
  imgHeight?: number;
  title: string;
  body: string;
  buttonTitle: string;
  border?: boolean;
  onClick?: () => void;
  children?: React.ReactNode;
  link?: string;
}

const borderRadius100 = { borderRadius: 100 };
export const BasicToolCard: React.FunctionComponent<
  BasicToolCardProps
> = props => {
  return (
    <PadBox>
      <Grid container rowSpacing={3}>
        <Grid item xs={12}>
          <Image
            alt="image"
            height={props.imgHeight ?? 51}
            width={props.imgWidth ?? 51}
            src={props.imgSrc}
          />
        </Grid>
        <Box marginTop={1.5}>
          <Typography variant={'h5'} fontWeight={'bold'}>
            {props.title}
          </Typography>
        </Box>
        <Box marginTop={3}>
          <Typography
            component="span"
            variant={'body1'}
            fontWeight={500}
            dangerouslySetInnerHTML={{ __html: props.body }}
          />
        </Box>
        <Grid item xs={12} marginTop={3.8}>
          <Box maxWidth={150}>
            <Button
              href={props.link ? props.link : undefined}
              id={props.id}
              fullWidth={true}
              variant="outlined"
              color="black"
              style={borderRadius100}
              onClick={props.onClick}>
              {props.buttonTitle}
            </Button>
          </Box>
          {props.children}
        </Grid>
      </Grid>
    </PadBox>
  );
};
