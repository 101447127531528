import { Colors } from '@/shared/util/colors';
import {
  Box,
  Container,
  Grid,
  Link,
  Pagination,
  PaginationItem,
  PaginationRenderItemParams,
  Typography,
} from '@mui/material';
import React from 'react';
import bigArrowRight from '@/shared/icons/big-arrow-right.svg';
import { useTranslation } from 'react-i18next';
import { ClassifiedItem } from '@/shared/lib-api';
import { BasicCarGalleryCard } from '../cards/basic-car-card/basic-car-gallery-card';
import { BasicCarCardType } from '@/shared/hooks/basic-car-hook';
import Image from '@/web/views/components/image/image';
interface PropsFromParent {
  Cars?: ClassifiedItem[];
  headerText: string;
  link?: URL;
  linkText?: string;
  id?: string;
}
function CarPostsSection(props: PropsFromParent) {
  const { t } = useTranslation();
  return (
    <>
      <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
        <Box
          display={'flex'}
          flexDirection={'row'}
          width={'100%'}
          paddingBottom={2}
          justifyContent={'space-between'}>
          <Typography variant={'h2'}>{t(props.headerText)}</Typography>
          <Box display={'flex'} alignItems="center">
            <Link
              id={props.id}
              style={{ textDecoration: 'none' }}
              href={props.link.toString()}>
              <Box alignItems={'center'} display={'flex'} flexDirection={'row'}>
                <Typography paddingRight={2} alignSelf={'center'}>
                  {props.linkText ? props.linkText : 'Se flere biler'}
                </Typography>
                <Image
                  alt={props.linkText ? props.linkText : 'Se flere biler'}
                  src={bigArrowRight.src}
                  width={25}
                  height={11}
                />
              </Box>
            </Link>
          </Box>
        </Box>
        <Grid container rowSpacing={3} columnSpacing={3}>
          {props.Cars.map(car => (
            <BasicCarGalleryCard
              origin={BasicCarCardType.SRPGallery}
              key={car.id}
              item={car}
              exclude-owner={false}
            />
          ))}
        </Grid>
      </Box>
    </>
  );
}

export default CarPostsSection;
