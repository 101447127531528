import { useScreenSizeContext } from '@/pages/_app';
import { Box, CircularProgress, TextField, Typography } from '@mui/material';
import * as React from 'react';
import DefaultInputError from './default-input-error';
import { DefaultInputWrapper } from './default-input-wrapper';

const DefaultItemInput: React.FC<{
  type: React.HTMLInputTypeAttribute;
  error?: string;
  multiline?: boolean;
  postfix?: string;
  prefix?: string;
  placeholder?: string;
  disable?: boolean;
  loadingAutoFillData?: boolean;
  value: unknown;
  children?: React.ReactNode;
  onBlur?: () => void;
  onChange: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void;
}> = props => {
  const style: React.CSSProperties = React.useMemo(() => {
    return { paddingTop: props.multiline ? 8 : 0, paddingLeft: 12 };
  }, [props.multiline]);
  const screenSize = useScreenSizeContext();

  return (
    <DefaultInputWrapper
      textArea={props.multiline}
      error={props.error}
      disable={props.disable}>
      <TextField
        onBlur={props.onBlur}
        InputProps={{
          startAdornment: props.prefix && (
            <Box paddingRight={0.4}>{props.prefix}</Box>
          ),
          endAdornment: props.postfix && (
            <Box paddingX={1} display="flex">
              {props.loadingAutoFillData && <CircularProgress size={16} />}
              {!props.loadingAutoFillData && (
                <Typography fontSize={16} variant="body1" color="#8C8C8C">
                  {props.postfix}
                </Typography>
              )}
            </Box>
          ),
        }}
        style={style}
        type={props.type}
        fullWidth
        disabled={props.disable}
        multiline={props.multiline}
        minRows={props.multiline ? (screenSize.isMobile ? 6 : 4) : 1}
        value={props.value}
        onChange={props.onChange}
        placeholder={props.placeholder}
      />
      {props.children}
      <DefaultInputError error={props.error} />
    </DefaultInputWrapper>
  );
};

export default React.memo(DefaultItemInput);
