import { useSeoSrp } from '@/shared/hooks/seo-srp-hook';
import { useAppSelector } from '@/shared/store/hooks';
import { Box, Button, Grid, Typography, styled } from '@mui/material';
import { default as React, useCallback, useMemo } from 'react';
import { ModelResults } from '../gallery-results';
import { thousandNumberSeperator } from '@/shared/util/general-helpers';
import { useRouter } from 'next/router';
import { createRoute } from '../../SRP/bottom-link-srp';
import { SearchDisplayResult } from '@/shared/lib-api';

const SubHeader = styled(Typography)({
  fontSize: '24px',
  fontWeight: '700',
});

const CTA = styled(Button)({
  borderRadius: '5px',
  fontSize: '18px',
  lineHeight: '30px',
  border: '1px solid #1066B6',
  padding: '10px 30px',
});

export const PopularModels: React.FC<{
  onlyBrand: SearchDisplayResult;
}> = props => {
  const { substitutedText } = useSeoSrp();
  const totalResults = useAppSelector(
    state => state.srpReducer.searchData.totalResults,
  );
  const router = useRouter();
  const vehicles = props.onlyBrand.items;

  const buttonText = substitutedText(
    `Se alle ${thousandNumberSeperator(
      totalResults,
    )} [category-p] [headline-p]`,
  );
  const searchOptions = useAppSelector(state => state.srpReducer.searchOptions);
  const hasFuelType = React.useMemo(() => {
    return searchOptions.fuelTypes[0]?.value;
  }, [router.asPath]);

  const isFrontPage = React.useMemo(() => {
    return router.asPath === '/';
  }, [router.asPath]);

  if (vehicles.length === 0) {
    return <></>;
  }
  return (
    <Box marginY={'45px'}>
      <SubHeader>
        {isFrontPage
          ? 'Populære modeller'
          : substitutedText(
              `Populære [category-p] [headline-p]${
                hasFuelType ? '' : ' modeller'
              }`,
            )}
      </SubHeader>
      <Box paddingTop={'22px'}>
        <ModelResults items={vehicles} />
      </Box>
      {!isFrontPage && (
        <Box
          marginTop={'15px'}
          display="flex"
          alignItems={'center'}
          justifyContent="center">
          <CTA
            id={'popular-models-cta'}
            onClick={() => {
              router.push(
                createRoute(
                  router.asPath,
                  -1,
                  undefined,
                  false,
                  true,
                  undefined,
                  true,
                ),
              );
            }}
            color="secondary"
            title={buttonText}>
            {buttonText}
          </CTA>
        </Box>
      )}
    </Box>
  );
};
