import { useFrontPageContext } from '@/pages';
import { useSiteSettingContext, useScreenSizeContext } from '@/pages/_app';
import { useSearchForm } from '@/shared/hooks/search-form-field-hook';
import { SearchPageAttribute } from '@/shared/lib-api';
import { useAppDispatch, useAppSelector } from '@/shared/store/hooks';
import {
  initialSearchAttribute,
  Reset,
} from '@/shared/store/reducers/SRPSlice';
import { Colors } from '@/shared/util/colors';
import { cursorPointer } from '@/shared/util/helpers/general-helper';
import { Box, Grid, Tooltip, Typography } from '@mui/material';
import React, { FC, useCallback, useEffect } from 'react';
import { SRPSection } from '../SRP/search-section';
import Image from '@/web/views/components/image/image';
import Link from 'next/link';

import svgCar from '@/shared/icons/search/normal-car-icon-fixed.svg';
import svgVan from '@/shared/icons/search/van-icon-fixed.svg';
import svgBus from '@/shared/icons/search/bus-icon-fixed.svg';
import svgTruck from '@/shared/icons/search/truck-icon-fixed.svg';
import svgCamper from '@/shared/icons/search/camper-icon-fixed.svg';
import { useTranslation } from 'react-i18next';
import { getExtendedRoute } from '@/web/util/helpers/srp-url-helpers';

interface Tab {
  label: string;
  icon: {
    src: string;
    height: number;
    width: number;
  };
  tabCategories: TabCategory[];
}

export interface SearchCardState {
  category: string;
  categoryMode: string;
  brand?: string;
  model?: string;
  year?: number;
  priceFrom?: number;
  priceTo?: number;
  proppelant?: string;
}

interface TabCategory {
  label: string;
  short: string;
  category: string;
  types: string[];
}

const Tabs: Tab[] = [
  {
    label: 'Alle',
    icon: {
      src: svgCar.src,
      height: 29,
      width: 60,
    },
    tabCategories: [
      { label: 'Alle biler', short: 'Alle biler', category: '', types: [] },
      {
        label: 'Brugte biler',
        short: 'Brugte',
        category: 'brugte-biler',
        types: [],
      },
      {
        label: 'Leasing',
        short: 'Leasing',
        category: 'leasing-biler',
        types: [],
      },
    ],
  },
  {
    label: 'Varebiler',
    icon: {
      src: svgVan.src,
      height: 32,
      width: 60,
    },
    tabCategories: [
      {
        label: 'Varebiler inkl. moms',
        short: 'Inkl. moms',
        category: 'varevogn-inkl-moms',
        types: [],
      },
      {
        label: 'Varebiler ekskl. moms',
        short: 'Ekskl. moms',
        category: 'varevogn-ekskl-moms',
        types: [],
      },
      {
        label: 'Leasing',
        short: 'Leasing',
        category: 'leasing-biler',
        types: ['varevogn'],
      },
    ],
  },
  {
    label: 'Busser',
    icon: {
      src: svgBus.src,
      height: 29,
      width: 60,
    },
    tabCategories: [
      {
        label: 'Alle busser',
        short: 'Alle busser',
        category: '',
        types: ['bus'],
      },
      {
        label: 'Brugte busser',
        short: 'Brugte',
        category: 'brugte-biler',
        types: ['bus'],
      },
      {
        label: 'Leasing',
        short: 'Leasing',
        category: 'leasing-biler',
        types: ['bus'],
      },
    ],
  },
  {
    label: 'Lastbiler',
    icon: {
      src: svgTruck.src,
      height: 29,
      width: 59,
    },
    tabCategories: [
      {
        label: 'Alle lastbiler',
        short: 'Alle lastbiler',
        category: '',
        types: ['lastbil'],
      },
      {
        label: 'Brugte lastbiler',
        short: 'Brugte',
        category: 'brugte-biler',
        types: ['lastbil'],
      },
      {
        label: 'Leasing',
        short: 'Leasing',
        category: 'leasing-biler',
        types: ['lastbil'],
      },
    ],
  },
  {
    label: 'Autocampere',
    icon: {
      src: svgCamper.src,
      height: 29,
      width: 60,
    },
    tabCategories: [
      {
        label: 'Alle autocampere',
        short: 'Alle autocampere',
        category: '',
        types: ['autocamper'],
      },
      {
        label: 'Brugte autocampere',
        short: 'Brugte',
        category: 'autocampere',
        types: ['autocamper'],
      },
      {
        label: 'Leasing',
        short: 'Leasing',
        category: 'leasing-biler',
        types: ['autocamper'],
      },
    ],
  },
];

export const SearchCardContent = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { submitSearch } = useFrontPageContext();
  const searchResult = useAppSelector(state => state.srpReducer.searchData);
  const searchFields = useAppSelector(state => state.srpReducer.searchFields);
  const { hostname } = useSiteSettingContext();
  const { isMobile } = useScreenSizeContext();

  const reset = useCallback(() => {
    dispatch(Reset());
  }, [dispatch]);

  const searchOptions = useAppSelector(state => state.srpReducer.searchOptions);

  const category = useAppSelector(
    state => state.srpReducer.searchOptions.category,
  );

  const carTypes = useAppSelector(
    state => state.srpReducer.searchOptions.carTypes,
  );

  var { onChange: onChangePriceFrom } = useSearchForm<SearchPageAttribute>(
    'priceFrom',
    undefined,
    false,
  );
  var { onChange: onChangePriceTo } = useSearchForm<SearchPageAttribute>(
    'priceTo',
    undefined,
    false,
  );
  var { onChange: onChangePricePerMonthFrom } =
    useSearchForm<SearchPageAttribute>('pricePerMonthFrom', undefined, false);
  var { onChange: onChangePricePerMonthTo } =
    useSearchForm<SearchPageAttribute>('pricePerMonthTo', undefined, false);
  var { onChange: onChangeCategory } =
    useSearchForm<SearchPageAttribute>('category');
  var { onChange: onChangeCarTypes } = useSearchForm<SearchPageAttribute[]>(
    'carTypes',
    undefined,
    false,
  );

  useEffect(() => {
    if (category.value === 'leasing-biler') {
      onChangePriceFrom(initialSearchAttribute);
      onChangePriceTo(initialSearchAttribute);
    } else {
      onChangePricePerMonthFrom(initialSearchAttribute);
      onChangePricePerMonthTo(initialSearchAttribute);
    }
  }, [
    category,
    onChangePriceFrom,
    onChangePriceTo,
    onChangePricePerMonthFrom,
    onChangePricePerMonthTo,
  ]);

  const handleSelectTabCategory = (selectedTabCategory: TabCategory) => {
    const carTypes = selectedTabCategory.types.map(t => {
      return { value: t, label: '' };
    });

    onChangeCarTypes(carTypes);
    onChangeCategory({ value: selectedTabCategory.category });
  };

  // hacky way of compaing arrays
  const equals = (a, b) => JSON.stringify(a) === JSON.stringify(b);

  const carTypesElement = (
    <Box display={'flex'} flexDirection={'row'}>
      {Tabs.map((t, index) => {
        const selectedCarTypes = carTypes.map(ct => ct.value);
        const isCurrent = t.tabCategories.some(
          tc =>
            tc.category === category.value &&
            equals(
              tc.types.map(typ => typ),
              selectedCarTypes,
            ),
        );

        const bgcolor = isCurrent
          ? Colors.White
          : Colors.Background.VipHeaderCard;

        const borderRadius =
          index === 0
            ? '10px 0px 0px 0px'
            : index === Tabs.length - 1
            ? '0px 10px 0px 0px'
            : undefined;

        return (
          <Tooltip key={index} title={t.label} placement="top">
            <Box
              display={'flex'}
              alignItems={'center'}
              justifyContent={'center'}
              height={{ xs: 44, md: 56 }}
              onClick={() => handleSelectTabCategory(t.tabCategories[0])}
              sx={cursorPointer}
              padding={{ xs: '4px', md: '20px' }}
              bgcolor={bgcolor}
              borderRadius={borderRadius}>
              <Image
                alt={t.label}
                src={t.icon.src}
                height={t.icon.height}
                width={isMobile ? t.icon.width - 15 : t.icon.width}
              />
            </Box>
          </Tooltip>
        );
      })}
    </Box>
  );

  const categoryOptions = (
    <>
      {Tabs.map((t, index) => {
        const selectedCarTypes = carTypes.map(ct => ct.value);
        const isCurrent = t.tabCategories.some(
          tc =>
            tc.category === category.value &&
            equals(
              tc.types.map(typ => typ),
              selectedCarTypes,
            ),
        );

        return isCurrent ? (
          <React.Fragment key={t.label}>
            {t.tabCategories.map((tc, yndex) => {
              return (
                <SearchCardContentLink
                  key={`${index}-${yndex}`}
                  onSelectTabCategory={handleSelectTabCategory}
                  {...tc}
                />
              );
            })}
          </React.Fragment>
        ) : (
          <React.Fragment key={t.label}></React.Fragment>
        );
      })}
    </>
  );

  return (
    <Box>
      {carTypesElement}
      <Box
        borderRadius={{ xs: '0px 0px 10px 10px', sm: '0px 10px 10px 10px' }}
        bgcolor={Colors.White}>
        <Box padding={{ xs: '11px 13px 16px 13px', sm: '31px 34px 27px 34px' }}>
          <Grid container columnSpacing={2.5}>
            {categoryOptions}
          </Grid>
          <Box
            paddingTop={{ xs: '11px', sm: '19px' }}
            borderTop={{ xs: '1px solid #E8E8E8', sm: '' }}>
            <SRPSection
              mode={'frontpage'}
              fieldsInSection={searchFields?.defaultSection}
              submitSearch={submitSearch}
              totalResults={searchResult?.totalResults}
            />
          </Box>
          <Box
            paddingTop={'5px'}
            display={'flex'}
            flexDirection={'row'}
            alignItems="baseline"
            justifyContent={{ xs: 'space-evenly', sm: 'space-between' }}>
            <Typography onClick={reset} variant={'clickAble'}>
              {t('Labels.Reset')}
            </Typography>
            <a href={getExtendedRoute(searchOptions, hostname).href}>
              <Typography variant={'clickAble'}>
                {t('Labels.ExpandedSearch')}
              </Typography>
            </a>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

interface FromParentProps extends TabCategory {
  onSelectTabCategory: (tabCategory: TabCategory) => void;
}
export const SearchCardContentLink: FC<FromParentProps> = props => {
  const { isMobile } = useScreenSizeContext();
  const category = useAppSelector(
    state => state.srpReducer.searchOptions.category,
  );

  const isCurrent = props.category === category.value;
  const borderBottom = isCurrent
    ? '3px solid ' + Colors.DarkBlue
    : '3px solid transparent';
  const color = isCurrent ? Colors.DarkBlue : '#847C75';

  return (
    <Grid item>
      <Box
        sx={cursorPointer}
        height={28}
        borderBottom={borderBottom}
        onClick={() => props.onSelectTabCategory(props)}>
        <Typography fontSize={17} color={color}>
          {isMobile ? props.short : props.label}
        </Typography>
      </Box>
    </Grid>
  );
};
