import { useCallback, useMemo } from 'react';
import { SearchPageAttribute } from '../lib-api';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { useRouter } from 'next/router';
import { capitalize } from '../util/general-helpers';
import { Areas } from '../models/areas';

interface Translation {
  singular: string;
  plural: string;
}

type CategoryTranslationKey =
  | 'brugte-biler'
  | 'leasing-biler'
  | 'busser'
  | 'alle-biler'
  | 'lastbiler'
  | 'autocampere'
  | 'biler-uden-afgift'
  | 'varevogn-ekskl-moms'
  | 'varevogn-inkl-moms';

type FuelTypeTranslationKey =
  | 'el'
  | 'benzin'
  | 'diesel'
  | 'benzinel'
  | 'dieselel';

const translations: Record<
  CategoryTranslationKey | FuelTypeTranslationKey,
  Translation
> = {
  'brugte-biler': { singular: 'Brugt', plural: 'Brugte' },
  'leasing-biler': { singular: 'Leasing', plural: 'Leasing' },
  busser: { singular: 'Bus', plural: 'Busser' },
  'alle-biler': { singular: 'Brugt', plural: 'Brugte' },
  lastbiler: { singular: 'Lastbil', plural: 'Lastbiler' },
  autocampere: { singular: 'Autocamper', plural: 'Autocampere' },
  'biler-uden-afgift': {
    singular: 'Bil uden afgift',
    plural: 'Biler uden afgift',
  },
  'varevogn-ekskl-moms': {
    singular: 'Brugt varevogn ekskl moms',
    plural: 'Brugte varevogne ekskl moms',
  },
  'varevogn-inkl-moms': {
    singular: 'Brugt varevogn inkl moms',
    plural: 'Brugte varevogne inkl moms',
  },
  el: {
    singular: 'Elbil',
    plural: 'Elbiler',
  },
  benzin: {
    singular: 'Benzinbil',
    plural: 'Benzinbiler',
  },
  diesel: {
    singular: 'Dieselbil',
    plural: 'Dieselbiler',
  },
  dieselel: {
    singular: 'Hybridbil',
    plural: 'Hybridbiler',
  },
  benzinel: {
    singular: 'Hybridbil',
    plural: 'Hybridbiler',
  },
};

export function useSeoSrp() {
  const searchOptions = useAppSelector(state => state.srpReducer.searchOptions);
  const router = useRouter();
  const brand = useMemo(() => {
    return searchOptions.makeModelVariantOptions
      ? searchOptions.makeModelVariantOptions[0].brand.label
      : undefined;
  }, [router.asPath]);
  const model = useMemo(() => {
    return searchOptions.makeModelVariantOptions
      ? searchOptions.makeModelVariantOptions[0].model.label
      : undefined;
  }, [router.asPath]);
  const fueltype = useMemo(() => {
    return searchOptions.fuelTypes ? searchOptions.fuelTypes[0] : undefined;
  }, [router.asPath]);

  const area = useMemo(() => {
    return searchOptions?.area?.value ? searchOptions.area.value : undefined;
  }, [router.asPath]);

  const handleBrugteBiler = (
    headline: string,
    category: string,
    initHeadline: string,
  ) => {
    let newHeadline = headline;
    newHeadline = newHeadline.replace(
      'Biler',
      translations[category].plural + ' biler',
    );

    if (newHeadline !== initHeadline) {
      return newHeadline;
    }

    newHeadline = newHeadline.replace(
      'Bil',
      translations[category].singular + ' bil',
    );
    if (newHeadline !== initHeadline) {
      return newHeadline;
    }

    newHeadline = newHeadline.replace(
      'biler',
      translations[category].plural.toLowerCase() + ' biler',
    );
    if (newHeadline !== initHeadline) {
      return newHeadline;
    }

    newHeadline = newHeadline.replace(
      'bil',
      translations[category].singular.toLowerCase() + ' bil',
    );
    return newHeadline;
  };
  const substitutedText = useCallback(
    (text: string) => {
      const isPlural =
        text.includes('[headline-p]') || text.includes('[Headline-p]');
      let initHeadline = isPlural ? 'biler' : 'bil';
      let headline = initHeadline;
      let make = '';
      let category = '';
      if (brand) {
        headline = brand;
        make = brand;
      }
      if (model) {
        headline += ' ' + model;
      }
      if (fueltype) {
        if (isPlural) {
          if (headline === initHeadline) {
            headline = headline.replace(
              'biler',
              translations[fueltype.value].plural.toLowerCase(),
            );
          } else {
            headline += ' ' + translations[fueltype.value].plural.toLowerCase();
            headline = headline.replace('hybridbiler', 'hybrid');
          }
        } else {
          if (headline === initHeadline) {
            headline = headline.replace(
              'biler',
              translations[fueltype.value].singular.toLowerCase(),
            );
          } else {
            headline +=
              ' ' + translations[fueltype.value].singular.toLowerCase();
            headline = headline.replace('hybridbil', 'hybrid');
          }
        }
      }

      category =
        searchOptions.category.value === ''
          ? 'alle-biler'
          : searchOptions.category.value;
      if (!brand && !model && !fueltype && headline === initHeadline) {
        text = text.replace('hybrid', 'hybridbiler');
        headline = handleBrugteBiler(headline, category, initHeadline);
      }
      var areaItem = '';
      if (area) {
        areaItem =
          Areas.find(p => p.value === area).prepos +
          ' ' +
          Areas.find(p => p.value === area).text;
      }
      //TODO: Handle leasing, plural/singular, etc.
      return text
        .replace(/\[headline-p\]/g, headline)
        .replace(/\[headline-s\]/g, headline)
        .replace(/\[Headline-p\]/g, capitalize(headline))
        .replace(/\[Headline-s\]/g, capitalize(headline))
        .replace(/\[make\]/g, make)
        .replace(/\[area\]/g, areaItem)
        .replace(
          /\[category-s\]/g,
          translations[category].singular.toLowerCase(),
        )
        .replace(/\[category-p\]/g, translations[category].plural.toLowerCase())
        .replace(/\[Category-s\]/g, translations[category].singular)
        .replace(/\[Category-p\]/g, translations[category].plural);
    },
    [searchOptions, brand, model, fueltype, area],
  );

  return {
    searchOptions,
    substitutedText,
  };
}

export function useSeoTextSrp() {
  const fieldExamples = useAppSelector(
    state => state.srpReducer.searchData.searchResultExamples,
  );
  const fields = useAppSelector(state => state.srpReducer.modelAttributes);
  const options = useAppSelector(state => state.srpReducer.searchOptions);
  const makes: SearchPageAttribute[] = useMemo(() => {
    let attributes = fieldExamples
      .filter(p => p.formName === 'brand' && p.count > 3)
      .map(p => {
        return { value: p.value, label: p.label };
      });
    return attributes;
  }, [fieldExamples]);

  const models: SearchPageAttribute[] = useMemo(() => {
    let modelsWithActiveVehicles = fieldExamples
      .filter(p => p.formName === 'model' && p.count > 3)
      .map(p => p.value);
    let attributes = fields;
    if (!attributes) {
      return [];
    }
    return attributes?.filter(p =>
      modelsWithActiveVehicles.find(
        t => (p.value.includes(t) && p.value.includes(',')) || p.value === t,
      ),
    );
  }, [fields, fieldExamples]);

  const carTypes: SearchPageAttribute[] = useMemo(() => {
    return fieldExamples
      .filter(p => p.formName === 'carTypes' && p.count > 3)
      .map(p => {
        return { value: p.value, label: p.label };
      });
  }, [fieldExamples]);

  const fuelTypes: SearchPageAttribute[] = useMemo(() => {
    return fieldExamples
      .filter(p => p.formName === 'fuelTypes' && p.count > 3)
      .map(p => {
        return { value: p.value, label: p.label };
      });
  }, [fieldExamples]);

  const areas: SearchPageAttribute[] = useMemo(() => {
    return fieldExamples
      .filter(
        p =>
          p.formName === 'Område' &&
          p.value !== 'postnummer-ikke-angivet' &&
          p.count > 3,
      )
      .map(p => {
        return { value: p.value, label: p.label };
      });
  }, [fieldExamples]);

  const cities: SearchPageAttribute[] = useMemo(() => {
    return fieldExamples
      .filter(p => p.formName === 'Postnummer' && p.count > 3)
      .map(p => {
        return { value: p.value, label: p.label };
      });
  }, [fieldExamples]);

  const allFields = useMemo(() => {
    return makes
      .concat(models, carTypes, fuelTypes, areas, cities)
      .map(p => p.value);
  }, [makes, models, carTypes, fuelTypes, areas, cities]);

  return {
    options,
    allFields,
    cities,
    areas,
    fuelTypes,
    carTypes,
    models,
    makes,
  };
}
